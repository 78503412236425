import React from "react";
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  Container,
  Form,
  Label,
  FormGroup,
  FormText,
  FormFeedback,
  Input,
  Button,
  Col,
  Row,
  Media
} from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { ToastContainer, toast } from 'react-toastify';
import { Link, graphql } from 'gatsby';
import Layout from '../components/Layout';
import SideBar from '../components/SideBar';
import Title from '../components/UIComponents/Title';
import LocalizedLink from '../components/LocalizedLink';
import TrainingSvg from '../media/training_0.svg';
import axios from 'axios';
import Helmet from 'react-helmet';
import HeadMeta from '../components/HeadMeta';
import TriDymeIcon from '../media/TriDymeIconV1.svg';


// import 'react-toastify/dist/ReactToastify.css';
const {
  BACKEND_API_URL,
  REACT_COMPANY_NAME,
} = process.env;

const metaData = {
  en: {
    title: `TriDyme | Training`,
    pageTitle: "Training",
    description: "Training lessons for Construction Industry"
  },
  fr: {
    title: `TriDyme | Formations`,
    pageTitle: "Formations",
    description: "Les Formations proposées par TriDyme pour le secteur de la Construction"
  }
};

const Formations = ({ pageContext: { locale }, data }) => {
  return (
    <Layout locale={locale}>
      <HeadMeta
        locale={locale}
        metaData={metaData}
      />
      <Container className="contactContainer">
        <ToastContainer />
        <Row>
          <Title
            icon={TriDymeIcon}
            title={metaData[locale].pageTitle}
          />
        </Row>
        <hr />
        <Row>
          <Col md={6}>
            <div >
              <FormattedMessage id="structure3D_alt">
                {
                  alt => (
                    <img
                      className="img"
                      src={TrainingSvg}
                      alt={alt}
                    />
                  )
                }
              </FormattedMessage>
            </div>
          </Col>
          <Col md={6}>
            <p>Afin d’aider les acteurs du BTP à enrichir leurs compétences, <a class="linkBlog" href="https://www.tridyme.com/fr" title="TriDyme" target="_blank">TriDyme</a> propose des formations en visio et <strong>prises en charge à 100% par l'Etat:</strong></p>
            <ul>
              <li><strong><a href="https://www.datbim.com/web/fichiers/files/Formation/BIM_pour_TOUS_analyse_structurelle.pdf" title="Initiation au dimensionnement structurel au sein
d’un processus BIM POUR TOUS" target="_blank">Initiation au dimensionnement structurel au sein
d’un processus BIM POUR TOUS</a></strong></li>
              <li><strong>Créer et partager vos applications web pour vos projets de construction</strong></li>
            </ul>
            <p>Toutes nos formations sont datadockées bénéficiant d'une prise en charge par votre OPCO.</p>
            <p>Renforcer votre principale richesse : le capital humain.</p>
            <p>Pour vous inscrire: <a href="https://www.datbim.com/fr/contact/" target="_blank">formulaire d'inscription.</a></p>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
};

export default Formations;